import React, { useContext, useState } from 'react';
import './loginscreen.scss'
import { useNavigate } from 'react-router-dom';
import { API } from '../../../helpers/apiEndpoints';
import { axiosInstance } from '../../../helpers/axiosInstance';
import { Auth } from '../../../App';
import { Spinner } from 'react-bootstrap';

const LoginScreen = ({ email, resendOtp, otpTimer, otpLimitMessage, setOtpLimitMessage }) => {
  const navigate = useNavigate()
  const [otp, setOtp] = useState('')
  const [emptyField, setEmptyField] = useState(false);
  const [wrongOtp, setWrongOtp] = useState(false)
  const { setIsLoggedIn } = useContext(Auth)
  const [loading, setLoading] = useState(false)

  const verifyOtp = () => {
    setOtpLimitMessage("");
    if (otp.length === 6) {
      setLoading(true)
      const adminUserCode = localStorage.getItem("adminUserCode")
      axiosInstance.post(API.VERIFY_LOGIN, {
        adminUserCode,
        otp
      }).then(res => {
        if (res?.status === 200) {
          setIsLoggedIn(true)
          localStorage.setItem('accessToken', res?.data?.result?.[0]?.accessToken);
          navigate('/main');
          setLoading(false)
        }
      })
        .catch(err => {
          setWrongOtp(err?.response?.data?.errorCode);
          setLoading(false)
        })
    } else if (otp === "") {
      setEmptyField(true)
    } else {
      setWrongOtp(true)
    }
  }


  // const enterOtp = (e) => {
  //   setEmptyField(false);
  //   setWrongOtp(false);
  //   const numericValue = e.target.value.replace(/\D/g, '');
  //   setOtp(numericValue.slice(0, 6));
  // };
  const enterOtp = (e) => {
    setOtpLimitMessage("");
    setEmptyField(false);
    setWrongOtp(false);
    const numericValue = e.target.value.replace(/[^0-9]/g, ''); // Allow only numeric characters
    const sanitizedValue = numericValue.replace(/[.-]/g, ''); // Remove hyphens and full stops
    setOtp(sanitizedValue.slice(0, 6));
  };



  const handleKeyDown = (e) => {
    if (e.key.toLowerCase() === 'e') {
      e.preventDefault();
    }
  };

  return (
    <div className='login_screen'>
      <div className="title">Login</div>
      <input type="email" placeholder="Email ID" disabled value={email} className='email_input' /><br />
      <div className='otp_verify'>
        <input
          type="text"
          placeholder="OTP"
          value={otp}
          onChange={enterOtp}
          className={emptyField || wrongOtp ? 'invalid_email_input' : 'email_input'}
          maxLength={6}
          onKeyDown={handleKeyDown}

        />
        <div className={otpLimitMessage ? "otp_limit_error" : !emptyField && !wrongOtp ? 'no_err_msg' : 'input_bottom_msg'}>
          {emptyField && !otpLimitMessage && <span className="err_msg">This field is required</span>}
          {wrongOtp && !otpLimitMessage && <span className="err_msg">Invalid OTP</span>}
          {otpLimitMessage && <span className="err_msg">{otpLimitMessage}</span>}
          {otpTimer > 0 && (
            <span className="otp_resend_timer_container">
              <span className="otp_resend_in">Resend OTP in </span>
              <span className="otp_resend_timer">{otpTimer}s</span>
            </span>
          )}
          {!otpTimer > 0 && !otpLimitMessage && (
            <span className="otp_verify_resend" onClick={() => {
              resendOtp();
              setEmptyField(false);
              setWrongOtp(false);
              setOtpLimitMessage("");
            }}>Resend OTP</span>
          )}
        </div>
      </div>
      <button className='submit_btn' onClick={() => !loading ? verifyOtp() : null}>
        {loading ? <Spinner animation="border" variant="light" size="sm" className="submit-button-thin-border" /> : 'Verify OTP'}
      </button><br />
    </div>
  )
}

export default LoginScreen
